.fundraisersTableCell,
.fundraisersNameCell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.fundraisersNameCell {
  color: var(--colors-primary-400);
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }
}
