.topBar {
  height: 72px;
}

.toolBar {
  width: calc(100% - 266px);
  min-height: 70px;
}

.breadcrumbs {
  ol {
    flex-wrap: nowrap;
    margin-left: 40px;
  }
}

.breadcrumb {
  color: var(--colors-gray-600);
  white-space: nowrap;
}

.breadcrumbActive {
  color: var(--colors-neutral-400);
  white-space: nowrap;
}

.userDetail {
  display: block;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;

  h6:first-child {
    font-weight: 600;
    color: var(--colors-black);
  }
}

.nameText {
  color: var(--colors-neutral-400);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
}

.roleText {
  color: var(--colors-gray-500);
}

.userProfileMenu {
  a {
    color: var(--colors-neutral-400);
    &:active {
      color: var(--colors-neutral-400);
    }
  }

  div:nth-last-child(2) {
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16) !important;
  }
}

.dropdown {
  transform: rotate(180deg);
}

.menuItem {
  display: flex;
  justify-content: space-between;
  border-left: 1px solid var(--colors-gray-100);
  padding-left: 0;
}

.link--disabled {
  pointer-events: none;
}

.userProfileMenuItem {
  width: 224px !important;

  svg {
    margin-right: 12px;
  }
}
