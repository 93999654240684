.tableCellId {
    padding: 0 20px 0 0;
    line-height: 24px;
    font-size: 13px;
    font-weight: 700;
    color: var(--colors-gray-600);
    white-space: nowrap;
    text-overflow: ellipsis;
  
  
    .spanId {
      display: inline-block;
      padding: 2px 12px;
      background-color: var(--colors-gray-70);
      border-radius: 4px;
    }
  }