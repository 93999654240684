.blurCellStyle {
  filter: blur(2px);
  position: relative;
}

.buttonStyle {
  position: absolute;
  top: 6px;
  left: 92px;
}

.relativeCellStyle {
  position: relative;
}

.keysHeader {
  padding-left: 0px;
}
