.footerContainer {
  margin-top: auto;
  padding: 10px 0 20px 0;
  min-width: 263px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  .footerText {
    display: flex;
    color: var(--colors-gray-300);
    align-items: center;
  }

  .dot {
    height: 5px;
    width: 5px;
    background-color: var(--colors-gray-100);
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .legal a {
    color: var(--colors-gray-600);
    text-decoration: underline;
    text-underline-position: under;
  }
}
