.linkTypography {
  padding: 30px;
  text-decoration: underline;

  a {
    color: var(--colors-gray-700);
    font-size: 12px;
  }
}

.keysContainer {
  border-radius: 8px;
  background-color: var(--colors-white);
}
