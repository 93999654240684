.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cellWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.disabled {
  color: var(--colors-gray-600);
}

.menu {
  div:nth-last-child(2) {
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16) !important;

    & > ul {
      padding: 4px 0px;
    }
  }
}

.selectCell {
  width: 20px;
  :hover {
    background-color: inherit;
  }
}

.menuItem {
  width: 166px !important;
  padding: 12px 16px;
}

.moreActions {
  border-bottom: 1px solid var(--colors-gray-600);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--colors-gray-600);
}