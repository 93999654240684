@mixin default {
  border-radius: 8px;
  height: 32px;
  padding-left: 24px;
  padding-right: 24px;
}

.alignLeft {
  float: left;
}

.alignCentre {
  float: none;
  @include default;
}

.alignRight {
  float: right;
}

.fullWidth {
  width: 100%;
}

.outlined {
  background-color: transparent;
  border: 1px solid var(--colors-primary-400);
  &:hover {
    background-color: transparent;
  }
}

.primaryTextColour {
  color: var(--colors-white);
}

.secondaryTextColour {
  color: var(--colors-primary-400);
}

.tertiaryTextColour {
  color: var(--colors-gray-600);
}

.underline {
  text-decoration: underline;
}
