#btnCloseDialog {
  display: inline-block;
  cursor: pointer;
  float: right;
  margin: 20px 0;
  color: transparent;
  background: none;
  border: none;
  text-align: right;
}

#dialogTitle {
  padding: 16px 0;
  display: inline-block;
}

#dialogHeader {
  margin: 0 24px;
}

.dialogContainer {
  border-radius: 8px;
}

.dialogMargin {
  margin: 0 24px 24px;
}
