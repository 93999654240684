.logo {
  display: flex;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 8px;
}

.navigationWithBanner {
  padding-top: 46px;
}
