.tableCellWrap {
  word-wrap: break-word;
  width: 300px;
  max-width: 300px;
}

.arrow {
  padding-left: 10px;
}

.rowVerificationArrow {
  cursor: pointer;
}

.tooltipCell {
  max-width: 200px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
  white-space: nowrap;
}

.naCell {
  color: var(--colors-gray-600);
}

.filtersContainer {
  display: flex;
  gap: 16px;
}

.filtersChipContainer {
  display: flex;
  gap: 8px;
  margin: 8px 0;
  flex-wrap: wrap;
}

.filtersWrapper {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.countryDetailsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
