.dialogTitle {
  display: inline-block;
  font-size: 20px;
  color: var(--colors-white);
  padding-left: 0px;
}

.drawer {
  box-shadow: 0 8px 32px rgba(33, 37, 53, 0.16) !important;

  ul {
    padding: 0px;
  }

  hr {
    background-color: var(--colors-white) !important;
  }

  div:nth-of-type(3) {
    border-radius: 0px !important;
    padding: 0 20px;
    background-color: var(--colors-black);
    width: 408px;
    overflow-x: hidden;
  }
}

.btnViewRawBtn,
.btnCloseViewRaw {
  color: var(--colors-neutral-400) !important;
  background-color: var(--colors-white);
  font-weight: 400;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.btnViewRawBtn {
  padding: 24px;
}

.btnCloseViewRaw {
  float: right;
  padding: 25px 0px;

  span {
    padding-left: 40px;
    margin-right: 0px;
  }

  path {
    fill: var(--colors-white);
  }
}

.drawerHeader,
.btnCloseViewRaw {
  background-color: var(--colors-black);
}

.drawerHeader {
  flex-basis: unset;
}
