.noResultTableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57vh;

  h6 {
    max-width: 320px;
    font-size: 18px;
    text-align: center;
  }
}
