.menuItem {
  background: var(--colors-white);
  border-radius: 4px;
  padding: 0px;
}

.menu {
  div {
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16) !important;
  }

  ul {
    padding: 0px;
  }
}

.ellipsisIcon {
  cursor: pointer;
}

.ellipsesCell {
  padding: 0px;

  li {
    float: right;
    padding-right: 0px;

    p {
      display: flex;
    }
  }
}
