.container {
    height: 200px;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    font-size: 14px;
    text-align: center;
    color: var(--colors-grey-160);
    
    div {
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.title {
    font-size: 18px;
    font-weight: 500;
    color: var(--colors-neutral-400);
}