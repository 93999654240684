.close {
  position: absolute;
  top: 31px;
  left: 50px;
  display: flex;
  cursor: pointer;
  height: 12px !important;
  width: 12px;
  padding: 0;
  background: transparent;
  border: none;

  svg {
    height: 12px;
    width: 12px;
    path {
      fill: var(--colors-gray-500);
    }
  }
}

.headerTwo {
  margin-top: 16px;
  text-align: left;
  color: var(--colors-gray-700);
}

.layoutContainer {
  > div > div {
    margin: auto !important;
  }
  color: var(--colors-black) !important;
}
