.successText {
  font-size: 14px;
  color: var(--colors-gray-600);
}

.signInLink {
  margin: 20px 0px 0px;

  a {
    font-size: 14px;
    color: var(--colors-gray-700);
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
}

.signInButtonWrapper {
  margin-top: 32px;
}
