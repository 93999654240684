.resetText {
  color: var(--colors-gray-700);
  margin-bottom: 20px;
  font-size: 14px;
}

.clickHere {
  color: var(--colors-gray-700);
  font-size: 14px;
  margin: 0;
  margin-top: 30px;
  a {
    color: var(--colors-gray-700);
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
}
