.title {
  display: inline-block;
  padding: 24px 0;
  font-size: 20px;
  font-weight: 500;
}

.closeDialog {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.divider {
  width: 100%; 
  border: 1px solid var(--colors-gray-80);
}
