.loggedOutText {
  margin: 0;
  margin-bottom: 15px;
  color: var(--colors-gray-700);
  text-align: justify;
}

.sessionExpiredText {
  margin: 15px 0 20px 0;
  font-weight: 400;
  font-size: 40px;
  text-align: left;
}

.sessionImage {
  width: 240px;
  height: 240px;
  margin-bottom: -60px;
}
