.noPrivilegeText {
  margin: 20px 0 40px;
  color: var(--colors-gray-800);
  font-size: 18px;

  a {
    color: var(--colors-neutral-400);
    text-decoration: underline;
  }
}

.container {
  text-align: center;

  h4 {
    color: var(--colors-neutral-400);
  }
}

.percentLogo {
  margin-top: 50px;
}

.lockLogo {
  margin: 50px auto;
}
