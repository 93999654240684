.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: -16px;
}

.label {
  white-space: nowrap;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;

  button:first-child {
    padding-right: 0;
  }
  button:last-child {
    padding-left: 8px;
  }
}

.paginationButtonDisabled {
}

.paginationButton {
  color: var(--colors-neutral-400);
}

.paginationButtonLast {
  padding-right: 0;
}
