.dateRangePopper {
  background-color: var(--colors-white);
  padding: 20px;
  box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16);
  border-radius: 4px;
}

.seperator {
  border-right: 1px solid var(--colors-gray-100);
  height: 32px;
  display: inline-block;
  margin: 0 12px;
}

.clearText {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btnCSVDownload,
.btnCSVLoading {
  border-radius: 8px;
  width: 139px;
  border: 1px solid var(--colors-primary-400);
  text-align: center;
  color: var(--colors-primary-400);
  background-color: var(--colors-white);
  span {
    display: block;

    span {
      float: right;
    }
  }
}

.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 24px;
}

.dateCell {
  padding: 0px !important;
}