.tableContainer {
  box-shadow: none;
  border-radius: 8px;
  padding: 16px;
}

.tabs {
  display: flex;
  height: 64px;

  button {
    min-width: auto;
    padding: 0 0;
    margin: 6px 32px 6px 0;

    &:hover {
      color: var(--colors-neutral-400);
    }
  }

  &.MuiTabs-scroller {
    border: 1px solid;
  }
}

.tabs.MuiTabs-scroller {
  border: 1px solid;
}

.indicator {
  background-color: var(--colors-gray-80);
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.table {
  border: none;

  thead tr th {
    font-size: 14px;
  }

  td,
  th {
    border: none;
    padding-right: 10px;
  }

  th:last-child,
  td:last-child {
    padding-right: 0px;
  }

  .footerCell {
    border-bottom: none;
    padding: 0 0 12px;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--colors-gray-100);
  margin-bottom: 16px;
  padding-left: 0;
  font-size: 20px;
  padding-right: 0px;
}

.tableAlertMessage {
  margin: 16px 0px;
}
