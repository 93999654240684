.container {
  position: absolute;
  width: 100%;
  z-index: 2147483647;
}

.bar {
  background-color: var(--colors-warning-100);
  text-align: center;
  border-radius: 0px 0px 8px 8px;
  height: 6px;
}

.message {
  font-size: 14px;
  color: var(--colors-warning-400);
  width: 260px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  transform: translateX(-120px);
  background-color: var(--colors-warning-100);
  border-radius: 0px 0px 8px 8px;
  top: 0;
  left: 50%;
  height: 32px;
  line-height: 32px;
  margin: 0;
}
