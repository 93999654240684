@mixin defaultLinkMixin {
  display: block;
  font-size: 14px;
  color: var(--colors-gray-600);
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  width: fit-content;
}

.defaultLink {
  @include defaultLinkMixin;
}

.centeredLink {
  @include defaultLinkMixin;
  margin: auto;
}
