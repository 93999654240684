.copyWrapper {
  display: flex;
  align-items: center;
  margin-right: 32px;
  gap: 5px;
}

.downloadWrapper {
  display: flex;
  align-items: center;
}

.action {
  margin-right: 8px;
}

.actionText {
  font-size: 13px;
  color: var(--colors-gray-600) !important;
}

.actionWrapper {
  display: flex;
  margin: 16px 0px;
  justify-content: center;
}

.download {
  fill: var(--colors-gray-600);
  width: 20px;
  height: 20px;
  border: 1px solid var(--colors-gray-200);
  border-radius: 8px;
  padding: 5px;
  margin-right: 8px;
}

.download:hover {
  cursor: pointer;
  fill: var(--colors-gray-700);
}

.subtext {
  font-size: 14px;
}
