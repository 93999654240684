.toolbar {
  margin-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.tableHeadRow {
  th {
    font-weight: 900;

    &:first-of-type {
      width: 80%;
    }
  }
}

.clearText {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
}

.dateRangeContainer {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.logoName {
  display: flex;
  align-items: center;
  div {
    margin-right: 9px;
  }
}
