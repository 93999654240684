.pageContainer {
  display: flex;
  background-color: #f8f6f2;
  position: relative;
}

.goodstackLogo {
  position: absolute;
  top: 44px;
  left: 80px;
}

.formAndImagesContainer {
  position: relative;
  z-index: 1;
}

.formContainer {
  background-color: var(--colors-white);
  text-align: center;
  border-radius: 8px;
  border: 4px solid #efede9;
  max-width: 480px;
  width: 480px;

  a svg {
    margin-bottom: 0;
  }
}

.headerImageWrapper {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 40px 0 0 40px;
}

.form {
  padding: 40px;
}

.logosWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;

  svg {
    margin: 0;
  }
}

.causeLogo {
  max-width: 100px;
  max-height: 50px;
}

.verifiedIcon {
  margin-top: 50px;
}

.headingText {
  margin: 0;
  margin-bottom: 30px;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  text-align: left;
}

.heartImage {
  position: absolute;
  bottom: 0;
  left: -100px;
  z-index: -1;
}

.smileImage {
  position: absolute;
  top: 120px;
  right: -110px;
  z-index: -1;
}

.goodstackLogoSmall {
  width: 120px;
}

@media only screen and (max-width: 576px) {
  .heartImage,
  .smileImage {
    display: none;
  }

  .formContainer {
    width: 420px;
  }
}

.footerWrapper {
  margin-top: auto;
}

.alignCenter {
  text-align: center;
}

.autoTopMargin {
  margin-top: auto;
}
