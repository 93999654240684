.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 26px;
  background-color: #f8f6f2;
}

.breadcrumbItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator {
  width: 32px;
  height: 1px;
  margin: 0 16px;
  background-color: var(--colors-gray-90) !important;
}

.activeStepText {
  font-weight: 400;
  font-size: 12px;
  color: var(--colors-gray-700) !important;
  margin: 0;
}

.activeStepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--colors-info-400) !important;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: var(--colors-info-400) !important;
  text-align: center;
  line-height: 18px;
  margin: 0 11px 0 0;
}

.stepText {
  font-weight: 400;
  font-size: 12px;
  color: var(--colors-gray-400) !important;
  margin: 0;
}

.stepNumber {
  font-weight: 700;
  font-size: 12px;
  color: var(--colors-gray-400) !important;
  margin: 0 11px 0 0;
}

.doneStep {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--colors-primary-400) !important;
}
