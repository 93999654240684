.container {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px !important;
}

.title {
  border-bottom: 1px solid var(--colors-gray-100);
  padding-bottom: 19px;
}

.text {
  font-size: 16px;
  font-weight: normal;
  color: var(--colors-neutral-400);
  margin: 0;
  padding: 0;
}

.subtext {
  font-size: 14px;
  font-weight: normal;
  color: var(--colors-gray-700);
  padding: 0;
  margin: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.optionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px 0;
  border-bottom: 1px solid var(--colors-gray-100);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    color: var(--colors-gray-700);
  }

  button {
    margin-right: 24px;
  }
}

.infoContainer {
  width: 100%;
  padding: 192px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothingHere {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  .header {
    font-size: 18px;
    font-weight: 500;
    color: #1B034F;
  }
  .text {
    color: #505869;
    font-size: 14px;

  }
}