.field {
  width: 100%;
  text-align: left;
  color: var(--colors-neutral-400);

  div {
    margin-top: 0px;
    border-bottom: none;
    display: block;

    &::before,
    &::after {
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }

  input {
    outline: none;
    display: block;
    width: 100%;
    height: 48px;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--colors-neutral-400);
    background-color: var(--colors-white);
    background-clip: padding-box;
    border: 1px solid var(--colors-gray-200);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:disabled {
      background: var(--colors-gray-70);
      border: 1px solid var(--colors-gray-200);
      box-sizing: border-box;
      border-radius: 8px;
      color: var(--colors-gray-80);
    }
  }
}

.searchFieldContainer {
  width: 75%;
}

.searchField {
  height: 48px;
  margin-right: 20px;

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none !important;
  }

  &:focus-visible {
    border: none !important;
  }

  input {
    &:focus {
      border: none !important;
    }
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--colors-neutral-400);
    height: 10px;
    background-color: transparent !important;
  }
  svg {
    fill: var(--colors-gray-600);
  }

  fieldset {
    border: 1px solid var(--colors-gray-100) !important;
    &:hover {
      border: none !important;
    }
  }
}
