.text {
  font-size: 14px;
  margin: 0;
}

.subtext {
  font-size: 14px;
  margin: 0;
}

.qrCode {
  width: 100%;
  margin: 32px 0;
}

.copyWrapper {
  display: flex;
  width: 100%;
  margin: 16px 0px;
}
